import { SEARCH_MAX_RESULT } from '@src/utils/constants/search';
import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
  VG_CREDENTIALS
} from '@src/utils/helpers/wsBase';
import dependencies, { keys } from '@src/utils/helpers/dependencies';

const consentApiURL = () => dependencies.get(keys.CONSENT_API_URL) || '/';
const apiURL = () => dependencies.get(keys.API_URL) || '/';

// Urls
export const getRecipeImageUrl = (userId, recipeId, scaleWidth) => {
  const query = scaleWidth ? `?scaleWidth=${scaleWidth}` : '';
  return `${apiURL()}api/v5/user/${userId}/recipe/${recipeId}/image${query}`;
};

const getHistoryFoodUrl = (userId, mealType) =>
  `${apiURL()}api/v5/user/${userId}/history/food?mealType=${mealType}`;

const getAllConsentsUrl = () =>
  `${consentApiURL()}communication/vk_newsletter/category/vektklubb/consents`;

const getUserConsentsUrl = spidId =>
  `${consentApiURL()}communication/vk_newsletter/user/${spidId}/consents`;

const putConsentsUrl = (userId, consentId) =>
  `${consentApiURL()}user/${userId}/consent/${consentId}`;

const recipeFoodTypeUrl = (userId, recipeId) =>
  `${apiURL()}api/v5/user/${userId}/recipe/${recipeId}/foodtype`;

const getHistoryExerciseUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/history/exercise`;

const getLoggedFoodItemUrl = (userId, date, mealType) =>
  `${apiURL()}api/v5/user/${userId}/log/${date}/food/${mealType}/item`;

const getLoggedFoodItemsUrl = (userId, date, mealType) =>
  `${apiURL()}api/v5/user/${userId}/log/${date}/food/${mealType}/items`;

const getPlanningFoodItemUrl = (userId, date, mealType) =>
  `${apiURL()}api/v5/user/${userId}/planning/${date}/food/${mealType}/item`;

const getUpdateLoggedFoodItemUrl = (userId, date, mealType, foodItemId) =>
  `${apiURL()}api/v5/user/${userId}/log/${date}/food/${mealType}/item/${foodItemId}`;

const getUpdatePlanningFoodItemUrl = (userId, date, mealType, foodItemId) =>
  `${apiURL()}api/v5/user/${userId}/planning/${date}/food/${mealType}/item/${foodItemId}`;

const getLoggedExerciseItemUrl = (userId, date) =>
  `${apiURL()}api/v5/user/${userId}/log/${date}/exercise`;

const getUpdateLoggedExerciseItemUrl = (userId, date, itemId) =>
  `${apiURL()}api/v5/user/${userId}/log/${date}/exercise/${itemId}`;

export const getLogUrl = (userId, date) =>
  `${apiURL()}api/v5/user/${userId}/log/${date}`;

export const getPlanningUrl = (userId, date) =>
  `${apiURL()}api/v5/user/${userId}/planning/${date}`;

const getDeleteLoggedFoodItemUrl = (userId, date, mealType, itemId) =>
  `${apiURL()}api/v5/user/${userId}/log/${date}/food/${mealType}/item/${itemId}`;

const getDeletePlanningFoodItemUrl = (userId, date, mealType, itemId) =>
  `${apiURL()}api/v5/user/${userId}/planning/${date}/food/${mealType}/item/${itemId}`;

const getDeleteLoggedExerciseItemUrl = (userId, date, itemId) =>
  `${apiURL()}api/v5/user/${userId}/log/${date}/exercise/${itemId}`;

export const getKcalUrl = (userId, fromDate, toDate) =>
  `${apiURL()}api/v5/user/${userId}/trends/netkcal?fromDate=${fromDate}&toDate=${toDate}`;

const getPlanningKcalUrl = (userId, anyDateInWeek) =>
  `${apiURL()}api/v5/user/${userId}/menu/week/${anyDateInWeek}/kcal`;

export const getProgramGoalUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/trends/programgoal`;

export const getWeightplanTrendUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/trends/plan`;

export const getWeightTrendUrl = (userId, fromDate, toDate) => {
  const fromDateParam = fromDate ? `fromDate=${fromDate}&` : '';
  return `${apiURL()}api/v5/user/${userId}/charts/weight?${fromDateParam}toDate=${toDate}`;
};

export const getUserIdsUrl = () => `${apiURL()}api/v5/userids`;

export const getWaistTrendUrl = (userId, fromDate, toDate) => {
  const fromDateParam = fromDate ? `fromDate=${fromDate}&` : '';
  return `${apiURL()}api/v5/user/${userId}/charts/waist?${fromDateParam}toDate=${toDate}`;
};

export const campaignURL = `${apiURL()}campaign-url`;

export const getExerciseSearchUrl = (
  userId,
  searchString,
  maxResult = SEARCH_MAX_RESULT
) =>
  `${apiURL()}api/v5/user/${userId}/search/exercise/?name=${searchString}&max=${maxResult}`;

export const getFoodSearchUrl = (
  userId,
  searchString,
  foodstuff,
  recipe,
  maxResult = SEARCH_MAX_RESULT
) => {
  let filter = '';

  if (foodstuff && !recipe) {
    filter = '&includeRecipes=false';
  } else if (!foodstuff && recipe) {
    filter = '&includeFoodstuff=false';
  } else if (!foodstuff && !recipe) {
    filter = '&includeRecipes=false&includeFoodstuff=false';
  }

  return `${apiURL()}api/v5/user/${userId}/search/food/?name=${searchString}&max=${maxResult}&wantedResponseAttributes=has_image${filter}`;
};

export const getAllFoodstuffsSearchUrl = (
  userId,
  searchString,
  maxResult = SEARCH_MAX_RESULT
) =>
  `${apiURL()}api/v5/user/${userId}/search/any-foodstuff/?searchTerm=${searchString}&max=${maxResult}`;

export const postRawRecipeSearchUrl = (
  userId,
  searchString = '',
  maxResult = SEARCH_MAX_RESULT,
  wantedResponseAttributes
) =>
  `${apiURL()}api/v5/user/${userId}/search/raw?${
    searchString ? `name=${searchString}&` : ''
  }max=${maxResult}&wantedResponseAttributes=${wantedResponseAttributes}`;

export const getRecipeSearchUrl = (
  userId,
  filters,
  maxResult = SEARCH_MAX_RESULT
) =>
  `${apiURL()}api/v5/user/${userId}/search/recipe/?&max=${maxResult}&wantedResponseAttributes=image,tags${filters}`;

const getFoodstuffUrl = (userId, foodstuffId) =>
  `${apiURL()}api/v5/user/${userId}/foodstuff/${foodstuffId}`;

const getCreateFoodstuffUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/foodstuff`;

const getCreateFoodstuffForAdminUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/foodstuff/admin`;

const getFoodstuffForAdminUrl = (userId, foodstuffId) =>
  `${getCreateFoodstuffForAdminUrl(userId)}/${foodstuffId}`;

const getRecipeBasicUrl = userId => `${apiURL()}api/v5/user/${userId}/recipe`;

const importSharedRecipeBasicUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/recipe/as-copy`;

const postRecipeReportUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/report/recipe`;

const getRecipeShareHashUrl = (userId, recipeId) =>
  `${apiURL()}api/v5/user/${userId}/recipe/${recipeId}/sharehash`;

const getRecipeUrl = (userId, recipeId, shareRecipeToken) =>
  `${apiURL()}api/v5/user/${userId}/recipe/${recipeId}?${
    shareRecipeToken ? `sharedRecipeToken=${shareRecipeToken}` : ''
  }`;

const getRefreshLoggedInUserUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/refreshloggedinwcuser`;

const getWeightplanUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/weightplan`;

const openRatingUrl = (userId, recipeId, params = '') =>
  `${apiURL()}api/v5/user/${userId}/recipe/${recipeId}/open-rating${params}`;

const getPartialGoalUrl = (
  userId,
  { startWeight, desiredWeight, height } = {}
) => {
  const params = startWeight
    ? `?startWeight=${startWeight}&desiredWeight=${desiredWeight}&height=${height}`
    : '';
  return `${apiURL()}api/v5/user/${userId}/partialgoal${params}`;
};

const getCalorieLeveslByPaceUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/program/calorielevelsbypace`;

const getCalorieLeveslByPaceNoProgramUrl = ({
  startDate,
  birthdate,
  startWeight,
  desiredWeight,
  height,
  male
}) =>
  `${apiURL()}api/v5/calorielevelsbypace?startDate=${startDate}&birthdate=${birthdate}&startWeight=${startWeight}&desiredWeight=${desiredWeight}&height=${height}&male=${male}`;

export const getFeaturedRecipeUrl = (userId, max = 3) =>
  `${apiURL()}api/v5/user/${userId}/search/recipe/selected/featured?max=${max}&wantedResponseAttributes=has_image,tags`;

export const getUserRecipeUrl = (userId, max = 3) =>
  `${apiURL()}api/v5/user/${userId}/recipe?max=${max}&wantedResponseAttributes=has_image`;

export const getUserFoodstuffUrl = (userId, max = 3) =>
  `${apiURL()}api/v5/user/${userId}/foodstuff?max=${max}`;

const getInspirationArticlesUrl = isWellobe => {
  const product = isWellobe ? 'wellobe' : 'vektklubb';
  return `/webapi/v1/pages/collections/?size=3&${product}_paywall=member-only|automatic`;
};

const getCsRightsUrl = userId =>
  `${apiURL()}api/v5/cs/user/${userId}/rights/${userId}`;

const getArticleUrl = articleId =>
  `/webapi/v1/pages/articles/${articleId}/paywall`;

const getNonMemberArticleUrl = articleId =>
  `/webapi/v1/pages/articles/${articleId}/sales`;

const getSectionUrl = (isWellobe, sectionId, params) => {
  const slug = sectionId
    ? `/v1/pages/sections/${sectionId}${params}`
    : `/v1/pages/sections/${params}`;

  return slug;
};

const getCollectionsUrl = params => `/webapi/v1/pages/collections/${params}`;

const getRelatedArticlesUrl = (isWellobe, articleId) =>
  `/webapi/v1/pages/articles/related/${articleId}`;

const getFoodstuffsToMergeUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/fooddataimporter/after-auto-merge`;

const numberOfLeftFoodstuffForMergeUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/fooddataimporter/after-auto-merge/left`;

const getSitemapUrl = () => `/webapi/v1/pages/sitemap`;

const getSalesposterUrl = (identifier, section) =>
  section
    ? `/webapi/sps/${identifier}/${section}`
    : `/webapi/sps/${identifier}`;

const putArticlePollUrl = articleId => `/webapi/v1/pages/poll/${articleId}`;

const getExperimentUrl = (userId, experimentName) =>
  `${apiURL()}api/v5/user/${userId}/experiments/${experimentName}`;

const getMenuUrl = (userId, date) =>
  `${apiURL()}api/v5/user/${userId}/menu/week/${date}`;

const getMenuAdminUrl = (userId, date, query) =>
  `${apiURL()}api/v5/user/${userId}/menu/admin/week/${date}?${query}`;

const getUserSettingsUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/settings`;

const getFoodPreferencesUrl = userId =>
  `${apiURL()}api/v5/user/${userId}/menu/preferences`;

const postFoodPreferencesUrl = (userId, applyToMenuImmediately) =>
  `${apiURL()}api/v5/user/${userId}/menu/preferences?applyToMenuImmediately=${applyToMenuImmediately}`;

const postSwitchMealUrl = (userId, date, mealType) =>
  `${apiURL()}api/v5/user/${userId}/menu/${date}/meal/${mealType}/group/selected`;

const getPaywallSectionsListUrl = sectionId =>
  `/webapi/v1/pages/sectionslist/paywall/${sectionId}`;

const getSalesSectionsListUrl = sectionId =>
  `/webapi/v1/pages/sectionslist/sales/${sectionId}`;

const getPaywallArticlesListUrl = (id, params) =>
  `/webapi/v1/pages/articleslist/paywall/${id}${params}`;

const getSalesArticlesListUrl = (id, params) =>
  `/webapi/v1/pages/articleslist/sales/${id}${params}`;

// Endpoints
export const getHistoryFood = (fulfilled, rejected, userId, mealType) =>
  getRequest(fulfilled, rejected, getHistoryFoodUrl(userId, mealType));

export const getHistoryExercise = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getHistoryExerciseUrl(userId));

export const postLoggedFoodItem = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  mealType
) =>
  postRequest(
    fulfilled,
    rejected,
    getLoggedFoodItemUrl(userId, date, mealType),
    payload
  );

export const postPlanningFoodItem = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  mealType
) =>
  postRequest(
    fulfilled,
    rejected,
    getPlanningFoodItemUrl(userId, date, mealType),
    payload
  );

export const putLoggedFoodItem = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  mealType,
  foodItemId
) =>
  putRequest(
    fulfilled,
    rejected,
    getUpdateLoggedFoodItemUrl(userId, date, mealType, foodItemId),
    payload
  );

export const postLoggedFoodItems = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  mealType
) =>
  postRequest(
    fulfilled,
    rejected,
    getLoggedFoodItemsUrl(userId, date, mealType),
    payload
  );

export const putPlanningFoodItem = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  mealType,
  foodItemId
) =>
  putRequest(
    fulfilled,
    rejected,
    getUpdatePlanningFoodItemUrl(userId, date, mealType, foodItemId),
    payload
  );

export const postLoggedExerciseItem = (
  fulfilled,
  rejected,
  payload,
  userId,
  date
) =>
  postRequest(
    fulfilled,
    rejected,
    getLoggedExerciseItemUrl(userId, date),
    payload
  );

export const putLoggedExerciseItem = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  itemId
) =>
  putRequest(
    fulfilled,
    rejected,
    getUpdateLoggedExerciseItemUrl(userId, date, itemId),
    payload
  );

export const deleteLoggedFoodItem = (
  fulfilled,
  rejected,
  userId,
  date,
  mealType,
  itemId
) =>
  deleteRequest(
    fulfilled,
    rejected,
    getDeleteLoggedFoodItemUrl(userId, date, mealType, itemId)
  );

export const deletePlanningFoodItem = (
  fulfilled,
  rejected,
  userId,
  date,
  mealType,
  itemId
) =>
  deleteRequest(
    fulfilled,
    rejected,
    getDeletePlanningFoodItemUrl(userId, date, mealType, itemId)
  );

export const deleteLoggedExerciseItem = (
  fulfilled,
  rejected,
  userId,
  date,
  itemId
) =>
  deleteRequest(
    fulfilled,
    rejected,
    getDeleteLoggedExerciseItemUrl(userId, date, itemId)
  );

export const getLogFromDate = (fulfilled, rejected, userId, date) =>
  getRequest(fulfilled, rejected, getLogUrl(userId, date));

export const getPlanningDay = (fulfilled, rejected, userId, date) =>
  getRequest(fulfilled, rejected, getPlanningUrl(userId, date));

export const getKcal = (fulfilled, rejected, userId, fromDate, toDate) =>
  getRequest(fulfilled, rejected, getKcalUrl(userId, fromDate, toDate));

const getPlanningKcal = (fulfilled, rejected, userId, date) =>
  getRequest(fulfilled, rejected, getPlanningKcalUrl(userId, date));

export const getProgramGoal = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getProgramGoalUrl(userId));

export const getWeightplanTrend = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getWeightplanTrendUrl(userId));

export const getWeightTrend = (fulfilled, rejected, userId, fromDate, toDate) =>
  getRequest(fulfilled, rejected, getWeightTrendUrl(userId, fromDate, toDate));

export const getWaistTrend = (fulfilled, rejected, userId, fromDate, toDate) =>
  getRequest(fulfilled, rejected, getWaistTrendUrl(userId, fromDate, toDate));

export const getUserIds = (fulfilled, rejected) =>
  getRequest(fulfilled, rejected, getUserIdsUrl());

export const getExercise = (
  fulfilled,
  rejected,
  userId,
  searchString,
  maxResult
) =>
  getRequest(
    fulfilled,
    rejected,
    getExerciseSearchUrl(userId, searchString, maxResult)
  );

export const getFood = (
  fulfilled,
  rejected,
  userId,
  searchString,
  isFoodstuff,
  isRecipe,
  maxResult
) =>
  getRequest(
    fulfilled,
    rejected,
    getFoodSearchUrl(userId, searchString, isFoodstuff, isRecipe, maxResult)
  );

export const getAllFoodstuffs = (
  fulfilled,
  rejected,
  userId,
  searchString,
  maxResult
) =>
  getRequest(
    fulfilled,
    rejected,
    getAllFoodstuffsSearchUrl(userId, searchString, maxResult)
  );

export const getRecipesAdvanced = (
  fulfilled,
  rejected,
  userId,
  product,
  filters,
  maxResult
) =>
  getRequest(
    fulfilled,
    rejected,
    getRecipeSearchUrl(userId, filters, maxResult)
  );

export const postRecipesRawSearch = (
  fulfilled,
  rejected,
  payload,
  userId,
  searchString,
  maxResult,
  wantedResponseAttributes
) =>
  postRequest(
    fulfilled,
    rejected,
    postRawRecipeSearchUrl(
      userId,
      searchString,
      maxResult,
      wantedResponseAttributes
    ),
    payload,
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

export const getFoodstuff = (fulfilled, rejected, userId, foodstuffId) =>
  getRequest(fulfilled, rejected, getFoodstuffUrl(userId, foodstuffId));

export const deleteFoodstuff = (fulfilled, rejected, userId, foodstuffId) =>
  deleteRequest(fulfilled, rejected, getFoodstuffUrl(userId, foodstuffId));

export const putFoodstuff = (
  fulfilled,
  rejected,
  userId,
  foodstuffId,
  payload
) =>
  putRequest(
    fulfilled,
    rejected,
    getFoodstuffUrl(userId, foodstuffId),
    payload
  );

export const putFoodstuffAsAdmin = (
  fulfilled,
  rejected,
  userId,
  foodstuffId,
  payload
) =>
  putRequest(
    fulfilled,
    rejected,
    getFoodstuffForAdminUrl(userId, foodstuffId),
    payload
  );

export const postFoodstuff = (fulfilled, rejected, userId, payload) =>
  postRequest(fulfilled, rejected, getCreateFoodstuffUrl(userId), payload);

export const postFoodstuffAsAdmin = (fulfilled, rejected, userId, payload) =>
  postRequest(
    fulfilled,
    rejected,
    getCreateFoodstuffForAdminUrl(userId),
    payload
  );

export const getRecipe = (
  fulfilled,
  rejected,
  userId,
  recipeId,
  shareRecipeToken
) =>
  getRequest(
    fulfilled,
    rejected,
    getRecipeUrl(userId, recipeId, shareRecipeToken)
  );

export const getFeaturedRecipes = (fulfilled, rejected, userId, max) =>
  getRequest(fulfilled, rejected, getFeaturedRecipeUrl(userId, max));

export const getUserRecipes = (fulfilled, rejected, userId, max) =>
  getRequest(fulfilled, rejected, getUserRecipeUrl(userId, max));

export const getUserFoodstuffs = (fulfilled, rejected, userId, max) =>
  getRequest(fulfilled, rejected, getUserFoodstuffUrl(userId, max));

export const getInspirationArticles = (fulfilled, rejected, isWellobe) =>
  getRequest(fulfilled, rejected, getInspirationArticlesUrl(isWellobe));

export const getArticle = (fulfilled, rejected, articleId) =>
  getRequest(
    fulfilled,
    rejected,
    getArticleUrl(articleId),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const getNonMemberArticle = (fulfilled, rejected, articleId) =>
  getRequest(
    fulfilled,
    rejected,
    getNonMemberArticleUrl(articleId),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );
export const getArticleSection = (
  fulfilled,
  rejected,
  isWellobe,
  sectionId,
  params
) =>
  getRequest(
    fulfilled,
    rejected,
    getSectionUrl(isWellobe, sectionId, params),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const getArticleCollection = (fulfilled, rejected, params, isWellobe) =>
  getRequest(
    fulfilled,
    rejected,
    getCollectionsUrl(params, isWellobe),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const getRelatedArticles = (fulfilled, rejected, isWellobe, articleId) =>
  getRequest(
    fulfilled,
    rejected,
    getRelatedArticlesUrl(isWellobe, articleId),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const getSitemap = (fulfilled, rejected) =>
  getRequest(
    fulfilled,
    rejected,
    getSitemapUrl(),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const getSalesposter = (
  fulfilled,
  rejected,
  identifier,
  section,
  variant
) =>
  getRequest(
    fulfilled,
    rejected,
    getSalesposterUrl(identifier, section),
    undefined,
    undefined,
    {
      'x-abx': variant
    },
    'same-origin'
  );

export const putArticlePoll = (fulfilled, rejected, articleId, payload) =>
  putRequest(fulfilled, rejected, putArticlePollUrl(articleId), payload);

export const postLogin = (fulfilled, rejected, payload) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/web/login`,
    payload,
    false,
    false
  );

export const postLogout = (fulfilled, rejected) =>
  postRequest(fulfilled, rejected, `${apiURL()}api/v5/web/logout`);

export const getWeightplan = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getWeightplanUrl(userId));

export const getRecipeShareHash = (fulfilled, rejected, userId, recipeId) =>
  getRequest(fulfilled, rejected, getRecipeShareHashUrl(userId, recipeId));

export const postRecipe = (fulfilled, rejected, payload, userId) =>
  postRequest(fulfilled, rejected, getRecipeBasicUrl(userId), payload);

export const importSharedRecipe = (fulfilled, rejected, payload, userId) =>
  postRequest(fulfilled, rejected, importSharedRecipeBasicUrl(userId), payload);

export const postRecipeReport = (fulfilled, rejected, payload, userId) =>
  postRequest(fulfilled, rejected, postRecipeReportUrl(userId), payload);

export const putRecipe = (fulfilled, rejected, payload, userId, recipeId) =>
  putRequest(fulfilled, rejected, getRecipeUrl(userId, recipeId), payload);

export const deleteRecipe = (fulfilled, rejected, userId, recipeId) =>
  deleteRequest(fulfilled, rejected, getRecipeUrl(userId, recipeId));

const putRecipefoodTypePayload = { value: 3 };

export const putRecipeFoodType = (fulfilled, rejected, userId, recipeId) =>
  putRequest(
    fulfilled,
    rejected,
    recipeFoodTypeUrl(userId, recipeId),
    putRecipefoodTypePayload
  );

export const postRecipeImage = (
  fulfilled,
  rejected,
  payload,
  userId,
  recipeId
) =>
  postRequest(
    fulfilled,
    rejected,
    getRecipeImageUrl(userId, recipeId),
    payload,
    false,
    false,
    {},
    undefined,
    false
  );

export const postWeightplan = (fulfilled, rejected, payload, userId) =>
  postRequest(fulfilled, rejected, getWeightplanUrl(userId), payload);

export const getPartialGoal = (fulfilled, rejected, userId, params) =>
  getRequest(fulfilled, rejected, getPartialGoalUrl(userId, params));

export const getCalorieLevelsByPace = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getCalorieLeveslByPaceUrl(userId));

export const getCalorieLevelsByPaceNoProgram = (fulfilled, rejected, params) =>
  getRequest(fulfilled, rejected, getCalorieLeveslByPaceNoProgramUrl(params));

export const postCalorieLevelsByPace = (fulfilled, rejected, payload, userId) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/program/pace`,
    payload,
    false
  );

export const postFastDays = (fulfilled, rejected, payload, userId) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/program/fastdays`,
    payload,
    false
  );

export const postQuickLogFood = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  mealType
) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/food/${mealType}/item/quick`,
    payload
  );

export const putQuickLogFood = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  mealType,
  foodItemId
) =>
  putRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/food/${mealType}/item/${foodItemId}/quick`,
    payload
  );

export const postQuickLogExercise = (
  fulfilled,
  rejected,
  payload,
  userId,
  date
) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/exercise/quick`,
    payload
  );

export const putQuickLogExercise = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  itemId
) =>
  putRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/exercise/quick/${itemId}`,
    payload
  );

export const getFavouriteFoodstuff = (fulfilled, rejected, userId) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/foodstuff/favourites`
  );

export const postFavouriteFoodstuff = (
  fulfilled,
  rejected,
  userId,
  foodstuffId
) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/foodstuff/favourites/${foodstuffId}`,
    null,
    false
  );

export const deleteFavouriteFoodstuff = (
  fulfilled,
  rejected,
  userId,
  foodstuffId
) =>
  deleteRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/foodstuff/favourites/${foodstuffId}`
  );

export const postFavouriteRecipe = (fulfilled, rejected, userId, recipeId) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/recipe/favourites/${recipeId}`,
    null,
    false
  );

export const deleteFavouriteRecipe = (fulfilled, rejected, userId, recipeId) =>
  deleteRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/recipe/favourites/${recipeId}`
  );

export const getFavouriteRecipes = (fulfilled, rejected, userId) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/recipe/favourites`
  );

export const getRecipeTags = (fulfilled, rejected, userId) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/recipe/tags?friendly=true`
  );

export const getWeight = (fulfilled, rejected, userId, date) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/weight?findLatest=true`,
    true,
    false
  );

export const postWeight = (fulfilled, rejected, payload, userId, date) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/weight`,
    payload
  );

export const getWaist = (fulfilled, rejected, userId, date) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/waist?findLatest=true`,
    true,
    false
  );

export const postWaist = (fulfilled, rejected, payload, userId, date) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/waist`,
    payload
  );

export const putMoodRating = (fulfilled, rejected, userId, date, rating) =>
  putRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/rating/${rating}`
  );

export const putNote = (fulfilled, rejected, payload, userId, date) =>
  putRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/log/${date}/note`,
    payload,
    false
  );

export const getRecipeRating = (fulfilled, rejected, userId, recipeId) =>
  getRequest(fulfilled, rejected, openRatingUrl(userId, recipeId), true, false);

export const putRecipeRating = (
  fulfilled,
  rejected,
  payload,
  userId,
  recipeId
) => putRequest(fulfilled, rejected, openRatingUrl(userId, recipeId), payload);

export const deleteRecipeRating = (
  fulfilled,
  rejected,
  userId,
  recipeId,
  sharedRecipeToken
) =>
  deleteRequest(
    fulfilled,
    rejected,
    openRatingUrl(
      userId,
      recipeId,
      sharedRecipeToken ? `?sharedRecipeToken=${sharedRecipeToken}` : ''
    )
  );

export const getRefreshLoggedInUser = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getRefreshLoggedInUserUrl(userId), false);

export const getCampaign = (fulfilled, rejected) =>
  getRequest(fulfilled, rejected, campaignURL);

export const getCsRights = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getCsRightsUrl(userId), true, true);

export const getUserConsents = (fulfilled, rejected, spidId, sig) =>
  getRequest(
    fulfilled,
    rejected,
    getUserConsentsUrl(spidId),
    true,
    true,
    {
      Authorization: `Bearer ${sig}`
    },
    VG_CREDENTIALS
  );

export const getPossibleConsents = (fulfilled, rejected, sig) =>
  getRequest(
    fulfilled,
    rejected,
    getAllConsentsUrl(),
    true,
    true,
    {
      Authorization: `Bearer ${sig}`
    },
    VG_CREDENTIALS
  );

export const setConsent = (
  fulfilled,
  rejected,
  userId,
  sig,
  consentId,
  value
) =>
  putRequest(
    fulfilled,
    rejected,
    putConsentsUrl(userId, consentId),
    { value },
    true,
    {
      Authorization: `Bearer ${sig}`,
      'Content-Type': 'application/json'
    },
    VG_CREDENTIALS
  );

export const getMenuDay = (fulfilled, rejected, userId, date) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/planning/${date}`
  );

export const getFoodstuffsToMerge = (userId, fulfilled, rejected) =>
  getRequest(fulfilled, rejected, getFoodstuffsToMergeUrl(userId));

export const putFoodstuffsToMerge = (
  userId,
  foodstuffsToMerge,
  fulfilled,
  rejected
) =>
  putRequest(
    fulfilled,
    rejected,
    getFoodstuffsToMergeUrl(userId),
    foodstuffsToMerge
  );

export const getNumberOfLeftFoodstuffForMerge = (userId, fulfilled, rejected) =>
  getRequest(fulfilled, rejected, numberOfLeftFoodstuffForMergeUrl(userId));

export const getHealthConsent = (fulfilled, rejected, userId) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/healthconsent`,
    false, // don't parse response
    false // don't parse error
  );

export const acceptHealthConsent = (fulfilled, rejected, userId, payload) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/healthconsent/accept`,
    payload,
    false, // don't parse response
    false // don't parse error
  );

export const declineHealthConsent = (fulfilled, rejected, userId, payload) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/healthconsent/decline`,
    payload,
    false, // don't parse response
    false // don't parse error
  );

export const getExperiment = (fulfilled, rejected, userId, experimentName) =>
  getRequest(
    fulfilled,
    rejected,
    getExperimentUrl(userId, experimentName),
    true,
    false
  );

export const getHealthDataConsentTemplate = (fulfilled, rejected, userId) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/productimprovconsent/template`
  );

export const getHealthDataConsent = (fulfilled, rejected, userId) =>
  getRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/productimprovconsent`
  );

// change url to /accept
export const acceptHealthDataConsent = (fulfilled, rejected, userId, payload) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/productimprovconsent/accept`,
    payload,
    false, // don't parse response
    false // don't parse error
  );

export const declineHealthDataConsent = (
  fulfilled,
  rejected,
  userId,
  payload
) =>
  postRequest(
    fulfilled,
    rejected,
    `${apiURL()}api/v5/user/${userId}/productimprovconsent/decline`,
    payload,
    false, // don't parse response
    false // don't parse error
  );

export const getMenu = (fulfilled, rejected, userId, date) =>
  getRequest(fulfilled, rejected, getMenuUrl(userId, date));

export const getMenuAdmin = (fulfilled, rejected, userId, date, query) =>
  getRequest(fulfilled, rejected, getMenuAdminUrl(userId, date, query));

export const getUserSettings = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getUserSettingsUrl(userId));

export const putUserSettings = (fulfilled, rejected, payload, userId) =>
  putRequest(fulfilled, rejected, getUserSettingsUrl(userId), payload);

export const getFoodPreferences = (fulfilled, rejected, userId) =>
  getRequest(fulfilled, rejected, getFoodPreferencesUrl(userId), true, false);

export const deleteFoodPreferences = (fulfilled, rejected, userId) =>
  deleteRequest(fulfilled, rejected, getFoodPreferencesUrl(userId));

export const postFoodPreferences = (
  fulfilled,
  rejected,
  userId,
  payload,
  applyToMenuImmediately
) =>
  postRequest(
    fulfilled,
    rejected,
    postFoodPreferencesUrl(userId, applyToMenuImmediately),
    payload,
    false
  );

export const postSwitchMeal = (
  fulfilled,
  rejected,
  payload,
  userId,
  date,
  mealType
) =>
  postRequest(
    fulfilled,
    rejected,
    postSwitchMealUrl(userId, date, mealType),
    payload,
    false
  );

export const getPaywallSectionsList = (
  fulfilled,
  rejected,
  isWellobe,
  articleId
) =>
  getRequest(
    fulfilled,
    rejected,
    getPaywallSectionsListUrl(isWellobe, articleId),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const getPaywallArticlesList = (
  fulfilled,
  rejected,
  sectionId,
  params
) =>
  getRequest(
    fulfilled,
    rejected,
    getPaywallArticlesListUrl(sectionId, params),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const getSalesArticlesList = (fulfilled, rejected, sectionId, params) =>
  getRequest(
    fulfilled,
    rejected,
    getSalesArticlesListUrl(sectionId, params),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const getSalesSectionsList = (
  fulfilled,
  rejected,
  isWellobe,
  articleId
) =>
  getRequest(
    fulfilled,
    rejected,
    getSalesSectionsListUrl(isWellobe, articleId),
    undefined,
    undefined,
    undefined,
    'same-origin'
  );

export const useApiWsCalls = () => {
  let apiWsCalls = {
    getRecipeImageUrl,
    getLogUrl,
    getPlanningUrl,
    getKcalUrl,
    getProgramGoalUrl,
    getWeightplanTrendUrl,
    getWeightTrendUrl,
    getUserIdsUrl,
    getWaistTrendUrl,
    campaignURL,
    getExerciseSearchUrl,
    getFoodSearchUrl,
    getAllFoodstuffsSearchUrl,
    postRawRecipeSearchUrl,
    getRecipeSearchUrl,
    getFeaturedRecipeUrl,
    getUserRecipeUrl,
    getUserFoodstuffUrl,
    getHistoryFood,
    getHistoryExercise,
    postLoggedFoodItem,
    postPlanningFoodItem,
    putLoggedFoodItem,
    putPlanningFoodItem,
    postLoggedExerciseItem,
    putLoggedExerciseItem,
    deleteLoggedFoodItem,
    deletePlanningFoodItem,
    deleteLoggedExerciseItem,
    getLogFromDate,
    getPlanningDay,
    getKcal,
    getProgramGoal,
    getWeightplanTrend,
    getWeightTrend,
    getWaistTrend,
    getUserIds,
    getExercise,
    getFood,
    getAllFoodstuffs,
    getRecipesAdvanced,
    postRecipesRawSearch,
    getFoodstuff,
    deleteFoodstuff,
    putFoodstuff,
    putFoodstuffAsAdmin,
    postFoodstuff,
    postFoodstuffAsAdmin,
    getRecipe,
    getFeaturedRecipes,
    getUserRecipes,
    getUserFoodstuffs,
    getInspirationArticles,
    getArticle,
    getNonMemberArticle,
    getArticleSection,
    getArticleCollection,
    getRelatedArticles,
    getSitemap,
    postLogin,
    postLogout,
    getWeightplan,
    getRecipeShareHash,
    postRecipe,
    importSharedRecipe,
    postRecipeReport,
    putRecipe,
    deleteRecipe,
    putRecipeFoodType,
    postRecipeImage,
    postWeightplan,
    getPartialGoal,
    getCalorieLevelsByPace,
    getCalorieLevelsByPaceNoProgram,
    postCalorieLevelsByPace,
    postFastDays,
    postQuickLogFood,
    putQuickLogFood,
    postQuickLogExercise,
    putQuickLogExercise,
    getFavouriteFoodstuff,
    postFavouriteFoodstuff,
    deleteFavouriteFoodstuff,
    postFavouriteRecipe,
    deleteFavouriteRecipe,
    getFavouriteRecipes,
    getRecipeTags,
    getWeight,
    postWeight,
    getWaist,
    postWaist,
    putMoodRating,
    putNote,
    getRecipeRating,
    putRecipeRating,
    deleteRecipeRating,
    getRefreshLoggedInUser,
    getCampaign,
    getCsRights,
    getUserConsents,
    getPossibleConsents,
    setConsent,
    getMenuDay,
    getFoodstuffsToMerge,
    putFoodstuffsToMerge,
    getNumberOfLeftFoodstuffForMerge,
    getHealthConsent,
    acceptHealthConsent,
    declineHealthConsent,
    getExperiment,
    getHealthDataConsentTemplate,
    getHealthDataConsent,
    acceptHealthDataConsent,
    declineHealthDataConsent,
    getPlanningKcal,
    getMenu,
    postLoggedFoodItems,
    getMenuAdmin,
    getFoodPreferences,
    postFoodPreferences,
    deleteFoodPreferences,
    getUserSettings,
    putUserSettings,
    postSwitchMeal,
    getSalesposter,
    getSalesSectionsList,
    getPaywallSectionsList,
    getPaywallArticlesList,
    getSalesArticlesList,
    putArticlePoll
  };

  const getApiWsCalls = () => apiWsCalls;

  const setApiWsCalls = newApiWsCalls => {
    apiWsCalls = newApiWsCalls;
  };

  return [getApiWsCalls, setApiWsCalls];
};
